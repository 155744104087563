/*
This is Hook is used to call a reload function after every number of milliseconds
params => callback function => which is reload the list
number of seconds to reload every time
*/
import { useEffect, useRef } from "react";

const useAutoReload = (cb, seconds, disableReload = false) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!disableReload) {
      ref.current = setInterval(cb, seconds * 1000);
    }
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [disableReload]);
};

export default useAutoReload;
