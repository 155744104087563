import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Link, useHistory, useLocation } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
// import { useDispatch } from "react-redux"
import { DeleteModal, ActivationModal } from "../Modals";
// import { notification } from "antd"

// custom hook
import useModuleActions from "hooks/useModuleActions";
// redux
import { useSelector } from "react-redux";

// utils
import {
  beneficaryListModuleId,
  claimFormModuleId,
  eventManagerModuleID,
  policyModuleId,
  predefinedModuleID,
  productionEventsId,
} from "utils/modulesIds";
import PremiumModal from "../Modals/PremiumModal";
import {
  financePaths,
  productionPaths,
  stakeholderPaths,
} from "common/data/routePaths";
import SpecialNotesModal from "../Modals/SpecialNotesModal";
import {
  allowedPrintPermissionStatuses,
  benefitIds,
  buttonsTitles,
  claimStatus,
  hidePrintPermissionStatuses,
  hideProcessingPermissionStatuses,
  modalHeaders,
  modalsStates,
  policyTypes,
} from "common/data";
import PremiumValidationModal from "components/TP/Beneficiary/PremiumValidationModal";
import { SingleRowAction } from ".";
import { MedicalFilesTableModal } from "components/TP/Claims";
import ChatModal from "components/TP/Chat/ChatModal";
import ClaimChatNotification from "components/TP/Chat/ClaimChatNotification";

const RowActions = ({
  cellProps,
  cellProps: {
    row: { original: row },
  },
  onDelete,
  onActivate,
  // onDeactivate,
  staticTable,
  onClickUpdate,
  onClickView,
  onClickStatus,
  onClickLog,
  // onClickUser,
  onClickDependency,
  onClickBenefit,
  onClickCopy,
  onClickStandardCode,
  onClickProcessing,
  onClickPrint,
  subModuleId,
  removeUpdatePermission,
}) => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const pageType = location.pathname.split("/")[2];

  const authUser = JSON.parse(localStorage.getItem("authUser"));

  //extract the  actions permissions from the custom hook
  const {
    isUpdatePermission,
    isSoftDeletePermission,
    isActivatePermission,
    isLogFilePermission,
    isDeactivatePermission,
    isBlackListPermission,
    isWhiteListPermission,
    isUserActivityPermission,
    isViewDependencyPermission,
    isCopyPermission,
    isViewPermission,
    isBeneficaryBenefitPermission,
    isBeneficaryViewPremiumPermission,
    isStandardCodePermission,
    isViewProcessingPermission,
    isProductAddPolicyPermission,
    isPolicyAddBeneficiaryPermission,
    isBeneficiarySpecialNotesPermission,
    isPolicyRenewalPermission,
    isViewEndorsementPermission,
    isBatchUploadPermission,
    isPrintPermission,
    isPrintInvoicePermission,
  } = useModuleActions(subModuleId && subModuleId);

  // get the module id form global state

  const moduleId = useSelector((state) => state?.Module?.actions?.id);

  const isPredefinedModule = moduleId === predefinedModuleID;

  // states

  // to toggle the delete modal
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // to toggle the activation modal
  const [isActivationModal, setIsActivationModal] = useState(false);
  // to toggle the premium modal
  const [isPremiumModal, setIsPremiumModal] = useState(false);
  // to toggle the validate premium modal
  const [isValidatePremiumModal, setIsValidatePremiumModal] = useState(false);

  // to toggle the special Notes modal
  const [isNotesModal, setIsNotesModal] = useState(false);

  const [isModal, setIsModal] = useState(false);
  // open delete modal
  const onClickDelete = () => {
    setIsDeleteModal(true);
  };

  // open activation modal
  const onClickActivate = () => {
    setIsActivationModal(true);
  };

  // open Premium modal
  const onClickViewPremium = () => {
    setIsPremiumModal(true);
  };
  // open Validate Premium modal
  const onClickValidatePremium = () => {
    setIsValidatePremiumModal(true);
  };

  const onClickSpeicalNotes = () => {
    setIsNotesModal(true);
  };

  return (
    pageType !== "view" && (
      <Fragment>
        <div className="d-flex gap-2">
          {/* View icon */}

          {isViewPermission && (
            <SingleRowAction
              onClick={onClickView}
              iconClass="mdi mdi-eye"
              actionName={isViewPermission?.name || "View"}
              record={row}
            />
          )}

          {/* Edit icon */}

          {isUpdatePermission && !removeUpdatePermission && (
            <SingleRowAction
              onClick={onClickUpdate}
              color="text-primary"
              iconClass="mdi mdi-pencil"
              actionName={isUpdatePermission?.name}
              record={row}
            />
          )}

          {/* Deactivate Icon */}

          {isDeactivatePermission &&
            row?.is_active === 1 &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) && (
              <SingleRowAction
                onClick={onClickActivate}
                color="text-info"
                iconClass="mdi mdi-checkbox-marked-circle"
                actionName={isDeactivatePermission?.name}
                record={row}
              />
            )}

          {/* Activate Icon */}

          {isActivatePermission && row?.is_active === 0 && (
            <SingleRowAction
              onClick={onClickActivate}
              color="text-danger"
              iconClass="mdi mdi-checkbox-marked-circle"
              actionName={isActivatePermission?.name}
              record={row}
            />
          )}

          {/* Delete Icon */}

          {isSoftDeletePermission &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) &&
            // hide the delete icon in event manger and production events module when is_executed === 1
            (moduleId === eventManagerModuleID ||
            moduleId === productionEventsId
              ? !row.is_executed
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickDelete}
                color="text-danger"
                iconClass="mdi mdi mdi-close"
                actionName={isSoftDeletePermission?.name}
                record={row}
              />
            )}

          {/* log history icon */}

          {isLogFilePermission && (
            <SingleRowAction
              onClick={onClickLog}
              // color="text-danger"
              iconClass="mdi mdi-history"
              actionName={isLogFilePermission?.name}
              record={row}
            />
          )}

          {(moduleId === claimFormModuleId
            ? allowedPrintPermissionStatuses.includes(row?.status?.id)
              ? true
              : false
            : true) && (
            <SingleRowAction
              onClick={onClickPrint}
              // color="text-danger"
              iconClass="mdi mdi-cloud-print-outline"
              actionName={isPrintPermission?.name || "print contract"}
              record={row}
            />
          )}

          {/* User Activity  icon */}

          {isUserActivityPermission && (
            <SingleRowAction
              to={`/users/${row?.id}/activity`}
              target="_blank"
              iconClass="mdi mdi-account-search"
              actionName={isUserActivityPermission?.name}
              record={row}
            />
          )}

          {/* Copy (clone) icon */}
          {/* remove it from PM Claims */}

          {moduleId === claimFormModuleId &&
            row?.benefit?.id !== benefitIds.pharmacy && (
              <SingleRowAction
                onClick={onClickCopy}
                iconClass="mdi mdi-content-copy"
                actionName={isCopyPermission?.name || "Copy"}
                record={row}
              />
            )}

          {/* Processing icon for claim */}
          {/* It will be displayed only if claim.status.id === 267 (received Claim Only) */}
          {isViewProcessingPermission &&
            !hideProcessingPermissionStatuses.includes(row?.status?.id) && (
              <SingleRowAction
                onClick={onClickProcessing}
                iconClass="mdi mdi-calculator"
                actionName={isViewProcessingPermission?.name}
                record={row}
              />
            )}

          {/* claim chat in claim only */}

          {moduleId === claimFormModuleId && (
            <span className="position-relative d-flex align-items-center justify-content-center">
              <SingleRowAction
                onClick={() => setIsModal(modalsStates.chatModal)}
                // color="text-danger"
                iconClass="bx bxs-chat"
                actionName={buttonsTitles.chat}
                record={row}
              />
              {/* Claim Notification Icon */}
              <ClaimChatNotification claim={row} />
            </span>
          )}
        </div>

        <DeleteModal
          show={isDeleteModal}
          onDeleteClick={() => {
            onDelete();
            setIsDeleteModal(false);
          }}
          onCloseClick={() => setIsDeleteModal(false)}
        />

        <ActivationModal
          show={isActivationModal}
          record={row}
          setIsActivationModal={setIsActivationModal}
          onConfirmClick={() => {
            onActivate();
            setIsActivationModal(false);
          }}
          onCloseClick={() => setIsActivationModal(false)}
        />

        {isPremiumModal && (
          <PremiumModal
            isPremiumModal={isPremiumModal}
            setIsPremiumModal={setIsPremiumModal}
            // beneficaryId={recordId}
            beneficaryId={row.id}
          />
        )}
        {isNotesModal && (
          <SpecialNotesModal
            show={isNotesModal}
            onCloseClick={() => setIsNotesModal(false)}
            beneficaryId={row.id}
            beneficary={row}
          />
        )}

        {isValidatePremiumModal && (
          <PremiumValidationModal
            isModal={isValidatePremiumModal}
            setIsModal={setIsValidatePremiumModal}
            beneficiary={row}
          />
        )}
        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={row?.id}
            notInClaim={true}
          />
        )}

        {/* Chat Modal */}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            resourceId={row?.id}
            claim={row}
            beneficiary={row?.beneficiary}
          />
        )}
      </Fragment>
    )
  );
};

export default RowActions;
