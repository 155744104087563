/* Paths */
import { claimPaths } from "common/data/routePaths";
import { lazy } from "react";

/*
Components
*/
// claim proceesing
const ClaimProcessing = lazy(() =>
  import("pages/Claims/ClaimProcessing/index")
);
const ClaimPrint = lazy(() =>
  import("pages/Claims/ClaimForm/Print/ClaimPrint")
);
const SearchResults = lazy(() =>
  import("pages/Claims/ClaimForm/SearchResults")
);

// Add Claim
const AddClaimForm = lazy(() => import("pages/Claims/ClaimForm/Tabs"));
// claim Validation
const ClaimValidation = lazy(() => import("pages/Claims/ClaimValidation"));
// bulk Batch Claim print
const BatchBulkPrint = lazy(() =>
  import("pages/Claims/BatchClaim/BatchBulkCard/BatchBulkPrint")
);

// Auto Claims Form
const AutoClaims = lazy(() => import("pages/Claims/AutoClaims"));

const BatchClaim = lazy(() => import("pages/Claims/BatchClaim"));
const AddBatchClaimGroup = lazy(() =>
  import("pages/Claims/BatchClaim/AddBatchClaimGroup")
);
const BatchClaimForms = lazy(() =>
  import("pages/Claims/BatchClaim/BatchClaimForms")
);
const BatchClaimPdf = lazy(() =>
  import("pages/Claims/BatchClaim/BatchClaimPrint/BatchClaimpdf")
);
// chronic form
const ChronicForm = lazy(() => import("pages/Claims/ChronicForm"));

const {
  claimBatch,
  claimForm: claimFormPath,
  reinmbursementClaim,
  claimProcessing,
  autoClaims,
  claimValidation,
  chronicForm,
} = claimPaths;
export const ClaimRoutes = [
  // Claim Form
  { path: claimFormPath, component: ClaimValidation },
  // Claim Processing
  { path: claimProcessing, component: ClaimProcessing },
  // Add Claim
  { path: `${claimFormPath}/add`, component: AddClaimForm },
  // Updated Claim
  { path: `${claimFormPath}/edit/:claimId`, component: AddClaimForm },
  // Processing Claim
  { path: `${claimFormPath}/processing/:claimId`, component: AddClaimForm },
  // search results Claim
  { path: `${claimFormPath}/search-results`, component: SearchResults },
  // print Claim
  {
    path: `${claimFormPath}/print/:claimId`,
    component: ClaimPrint,
  },
  // view Claim
  {
    path: `${claimFormPath}/view/:claimId`,
    component: AddClaimForm,
  },

  // Claim Validation
  { path: claimValidation, component: ClaimValidation },

  // Batch Claim
  { path: claimBatch, component: BatchClaim },
  // Add Batch Claim
  { path: `${claimBatch}/add`, component: BatchClaimForms },
  // Add Batch Claim Group
  { path: `${claimBatch}/group`, component: AddBatchClaimGroup },
  // Edit Batch Claim
  { path: `${claimBatch}/edit/:batchClaimId`, component: BatchClaimForms },
  // View Batch Claim
  { path: `${claimBatch}/view/:batchClaimId`, component: BatchClaimForms },
  // Print Receipt
  { path: `${claimBatch}/print/:id`, component: BatchClaimPdf },

  // Print bulk card
  { path: `${claimBatch}/bulk-card-print`, component: BatchBulkPrint },

  /* Chronic Form */
  { path: chronicForm, component: ChronicForm },

  // Auto Claims
  { path: autoClaims, component: AutoClaims },
];
